var portadakoIrudiak = require('../_data/portadakoIrudiak.json');
import { CountUp } from './countUp.min.js';
import { goiburukoAnimazioa } from './gure-funtzioak.js';
import AOS from 'aos';
import Swiper, { Navigation, Pagination, EffectCoverflow  } from 'swiper';

window.onload = function() {

  //Goiburuko animazioa
  goiburukoAnimazioa();
  if(document.getElementById("target2000")){
    var countUp2000 = new CountUp('target2000', 2000, { enableScrollSpy: true, scrollSpyOnce: true, scrollSpyDelay: 500, separator: '.' });
    countUp2000.start();
  }
  if(document.getElementById("target1640")){
    var countUp1640 = new CountUp('target1640', 1640, { enableScrollSpy: true, scrollSpyOnce: true, scrollSpyDelay: 500, separator: '.' });
    countUp1640.start();
  }
  if(document.getElementById("target360")){
    var countUp360 = new CountUp('target360', 360, { enableScrollSpy: true, scrollSpyOnce: true, scrollSpyDelay: 500, separator: '.' });
    countUp360.start();
  }
  //Zerrenda animazioak
  AOS.init();

  document.getElementById("topBtn").addEventListener("click", function(event){
    goToTop();
  });

  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      var panel = this.nextElementSibling;
      this.classList.toggle("active");
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      } 
    });

  }
  if(acc[0]) {
    acc[0].click();
  }


}

//Portadako irudia random
var zenbakia = Math.floor(Math.random() * portadakoIrudiak.length);
if(document.getElementById('portadakoIrudia')) {
  setTimeout(function() {
    document.getElementById('portadakoIrudia').setAttribute('src', portadakoIrudiak[zenbakia].src);
    document.getElementById('portadakoIrudia').style.opacity = 1; // Fade in the new image
  }, 200); // Wait for 1 second (1000 milliseconds) before changing the image source
  document.getElementById('portadakoIrudia').setAttribute('alt', window.location.pathname.startsWith("/eu")? portadakoIrudiak[zenbakia].altEu : portadakoIrudiak[zenbakia].altEs);
}

//Swiper
var swiper = new Swiper(".swiper", {
  modules: [Navigation, Pagination, EffectCoverflow ],
  effect: "coverflow",
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: "auto",
  allowTouchMove: false,
  coverflowEffect: {
    rotate: 10,
    stretch: 100,
    depth: 200,
    modifier: 1,
    scale: 1,
    slideShadows: true,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
},
});

const burguer = document.querySelector('#menuResponsive');
const menu = document.querySelector('#menuResponsive .nav');
if(burguer) {
  burguer.addEventListener('click', e => {
      burguer.classList.toggle('active');
      menu.classList.toggle('open');
      });
}
const overlay = document.querySelector('.overlay');
const close = document.querySelector('.close');
if(close) {
  close.addEventListener('click', e => {
      overlay.classList.toggle('active');
      modal.classList.toggle('active');
      });
}

//Go To Top button
addEventListener("scroll", (event) => {
  scrollFunction();
});

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("topBtn").style.display = "block";
  } else {
      document.getElementById("topBtn").style.display = "none";
  }
};

function goToTop() {
  window.scrollTo({top: 0, behavior: "smooth"});
};
