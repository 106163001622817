[
    {
        "src": "/img/portadakoIrudiak/GKAI-herri-kiroletan-lokotxak.jpg",
        "altEu": "GKAI - Zurriola ikastola - Animazio turistikoko kultura jarduerak: herri kiroletan lokotxak",
        "altEs": "GKAI - Zurriola ikastola - Actividades culturales de animación turística: lokotx biltzea, deporte tradicional vasco"
    },
    {
        "src": "/img/portadakoIrudiak/GKAI-atletismoan-hesiak-lantzen.jpg",
        "altEu": "GKAI - Zurriola ikastola - Banakako kirol jarduera fisikoak: atletismoan hesiak lantzen",
        "altEs": "GKAI - Zurriola ikastola - Actividades físicas individuales: salto de vallas en atletismo"
    },
    {
        "src": "/img/portadakoIrudiak/GKAI-futbola.jpg",
        "altEu": "GKAI - Zurriola ikastola - Taldeko kirol-jarduera fisikoak: futbola",
        "altEs": "GKAI - Zurriola ikastola - Actividades físicas grupales: fútbol"
    },
    {
        "src": "/img/portadakoIrudiak/GKAI-TRX-ariketak.jpg",
        "altEu": "GKAI - Zurriola ikastola - Egoera fisikoa hobetzeko TRX ariketak egiten",
        "altEs": "GKAI - Zurriola ikastola - Ejercicios TRX para mejorar la condición física"
    },
    {
        "src": "/img/portadakoIrudiak/GKAI-indarra-lantzeko-ariketak.jpg",
        "altEu": "GKAI - Zurriola ikastola - Egoera fisikoa baloratzea: indarra lantzeko ariketak",
        "altEs": "GKAI - Zurriola ikastola - Valoración de la condición física: actividades para trabajar la fuerza"
    },
    {
        "src": "/img/portadakoIrudiak/GKAI-ezagutza-jolasak.jpg",
        "altEu": "GKAI - Zurriola ikastola - Animazio turistikoko jolasak eta jolas-jarduera fisikoak: ezagutza jolasak",
        "altEs": "GKAI - Zurriola ikastola - Juegos y actividades físico-recreativas y de animación turística: juegos de conocimiento"
    },
    {
        "src": "/img/portadakoIrudiak/GKAI-bertako-ingurune-naturalak-ezagutzen.jpg",
        "altEu": "GKAI - Zurriola ikastola - Natura-inguruneko jolas jarduera fisikoak: bertako ingurune naturalak ezagutzen",
        "altEs": "GKAI - Zurriola ikastola - Actividades físicas y juegos en el medio natural: conociendo el entorno natural cercano"
    }
]
